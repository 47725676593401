// @flow strict
import React from 'react';
import styles from './Content.module.scss';

type Props = {
  body: string,
  title: string
};

const Content = ({ body, title }: Props) => (
  <div className={styles['content']}>
    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
    {/* <-- marcuseisele.com top horizontal --> */}
    <ins className="adsbygoogle"
     style={{ display: 'block' }}
     data-ad-client="ca-pub-7650678322458133"
     data-ad-slot="5657212988"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>
    <script>
     (adsbygoogle = window.adsbygoogle || []).push({});
    </script>
    {/* <-- marcuseisele.com top horizontal --> */}
    <h1 className={styles['content__title']}>{title}</h1>
    <div className={styles['content__body']} dangerouslySetInnerHTML={{ __html: body }} />
  </div>
);

export default Content;
